import { ConfirmationModal } from "../../modals/confirmation"
import { GameOverModal } from "../../modals/game_over"
import { InstructionsModal } from "../../modals/instructions"
import { LanguageModal } from "../../modals/language"
import { PlayersModal } from "../../modals/players"
import { PlungeModal } from "../../modals/plunge"
import { SettingsModal } from "../../modals/settings"

export const navigationModals = {
  confirmation: ConfirmationModal,
  gameOver: GameOverModal,
  instructions: InstructionsModal,
  players: PlayersModal,
  language: LanguageModal,
  plunge: PlungeModal,
  settings: SettingsModal,
}

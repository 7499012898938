import { Player } from "../player"
import { playerUsedPlunge } from "./player_used"
import type { Game } from "../game"

export const allPlayersUsedPlunge = ({
  game,
}: {
  game: Game.Instance
}): boolean => {
  return Player.getActive(game).every(({ id: playerId }): boolean => {
    return playerUsedPlunge({ game, playerId })
  })
}

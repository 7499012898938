import React from "react"
import type { ReactElement } from "react"

import { Card } from "../../shared/card"
import { Deck } from "../../lib/deck"
import { DeckSelection } from "../../shared/deck_selection"
import { Game } from "../../lib/game"
import { Language } from "../../lib/language"
import { Navigation } from "../../lib/navigation"
import { PlayerTurnIndicator } from "../../shared/player_turn_indicator"
import { Plunge } from "../../lib/plunge"
import { PlungeButton } from "../../shared/plunge_button"
import { Progress } from "../../lib/progress"
import { ScreenLayout } from "../../layouts/screen"
import { SettingsButton } from "../../shared/settings_button"

type Props = Game.ContextData & {
  navigation: Navigation.ContextData
}

type State = {}

export class PlayScreenComponent extends React.Component<Props, State> {
  private abortController?: AbortController

  public componentDidMount = (): void => {
    this.abortController = new AbortController()

    window.addEventListener(
      "keydown",
      (event) => {
        if (event.key === "ArrowLeft") {
          this.props.undo()
          return
        }

        if (event.key === "ArrowRight") {
          this.progressTurn()
          return
        }
      },
      { signal: this.abortController.signal },
    )
  }

  public componentWillUnmount = (): void => {
    this.abortController?.abort()
  }

  private maybeOfferNextDeckAfterPlunge = (): boolean => {
    const { game } = this.props

    const nextDeckId = Plunge.nextDeckOffer({ game })
    if (!nextDeckId) return false

    this.props.navigation.showModal({
      modalId: "confirmation",
      props: {
        onConfirm: () => {
          return this.props.tick({
            game: Game.nextTurn({
              game: {
                ...game,
                deckId: nextDeckId,
                turnsSinceDeckChanged: -1,
              },
            }),
          })
        },
        onDismiss: () => {
          return this.props.tick({
            game: {
              ...Game.nextTurn({ game: game }),
              plungeDeckProgressionRejected: {
                ...game.plungeDeckProgressionRejected,
                [game.deckId]: true,
              },
            },
          })
        },
        text: {
          cancel: Language.t("deck_selection_modal.cancel", {
            level_number: Deck.getLevelNumber({ deckId: game.deckId }),
          }),
          confirm: Language.t("deck_selection_modal.confirm", {
            level_number: Deck.getLevelNumber({ deckId: nextDeckId }),
          }),
          title: Language.t("deck_selection_modal.title"),
        },
      },
    })
    return true
  }

  private maybeOfferNextDeckForProgress = (): boolean => {
    const game = this.props.game
    const nextDeckId = Progress.nextDeckOffer({ game })
    if (!nextDeckId) return false

    this.props.navigation.showModal({
      modalId: "confirmation",
      props: {
        onConfirm: () => {
          return this.props.tick({
            game: Game.nextTurn({
              game: {
                ...game,
                deckId: nextDeckId,
                turnsSinceDeckChanged: -1,
              },
            }),
          })
        },
        onDismiss: () => {
          return this.props.tick({
            game: {
              ...Game.nextTurn({ game: game }),
              roundBasedDeckProgressionRejected: {
                ...game.roundBasedDeckProgressionRejected,
                [game.deckId]: true,
              },
            },
          })
        },
        text: {
          cancel: Language.t("deck_selection_modal.cancel", {
            level_number: Deck.getLevelNumber({ deckId: game.deckId }),
          }),
          confirm: Language.t("deck_selection_modal.confirm", {
            level_number: Deck.getLevelNumber({ deckId: nextDeckId }),
          }),
          title: Language.t("deck_selection_modal.title"),
        },
      },
    })
    return true
  }

  private progressTurn = () => {
    if (this.maybeOfferNextDeckAfterPlunge()) {
      return
    }

    if (this.maybeOfferNextDeckForProgress()) {
      return
    }

    const game = Game.nextTurn({ game: this.props.game })

    this.props.tick({ game })
  }

  public render = (): ReactElement => {
    const game = this.props.game
    const { players } = game
    const turn = Game.getCurrentTurn({ game })

    return (
      <ScreenLayout>
        <SettingsButton />

        <div className="flex-1 w-full flex flex-col justify-between space-y-4 items-center px-4 pb-10">
          <PlayerTurnIndicator players={players} turn={turn} />

          <div className="w-full flex flex-col justify-center">
            <Card
              card={Game.getCurrentCard({ game: game })}
              onNextClick={this.progressTurn}
            />
          </div>

          <div className="w-full flex flex-row justify-center items-center space-x-2">
            <PlungeButton
              game={game}
              turn={turn}
              updateGame={this.props.updateGame}
            />
          </div>

          <DeckSelection
            className="w-full"
            game={game}
            onDeckSelected={this.props.tick}
          />
        </div>
      </ScreenLayout>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  label: string
  result: string
}

type State = {}

export class StatisticEntryComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <div
        className={`text-bright flex flex-row justify-between font-bold text-xl ${
          this.props.className || ""
        }`}
      >
        <p>{this.props.label}</p>
        <p>{this.props.result}</p>
      </div>
    )
  }
}

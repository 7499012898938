import shuffle from "lodash.shuffle"

import { Player } from "../player"
import type { Progress } from "."

export const buildTurnOrder = ({
  players,
  previousRound,
}: {
  players: Player.Collection
  previousRound?: Progress.Round
}): Progress.Turn[] => {
  let answerers = shuffle(Player.getActive({ players })).map(({ id }) => {
    return id
  })

  let pairings = shuffle(
    answerers.map((answerer, index, list): Progress.Turn => {
      return {
        answerer: answerer,
        questioner: list[index === list.length - 1 ? 0 : index + 1],
      }
    }),
  )

  if (previousRound) {
    const finalAnswererFromLastRound =
      previousRound?.turns.slice(-1)[0].answerer

    while (pairings[0].answerer === finalAnswererFromLastRound) {
      pairings = shuffle(pairings)
    }
  }

  return pairings
}

import React from "react"
import type { ReactElement } from "react"

import { Language } from "../../lib/language"
import { ModalLayout } from "../../layouts/modal"

type Props = {}

type State = {}

export class InstructionsModalComponent extends React.Component<Props, State> {
  private formatHTML = (copy: string): string => {
    return copy
      .replaceAll(
        "<ol>",
        "<ol class='block list-decimal list-outside my-4 ml-5'>",
      )
      .replaceAll("<li>", "<li class='my-2 pl-2'>")
      .replaceAll("<p>", "<p class='mt-4'>")
  }

  public render = (): ReactElement => {
    return (
      <ModalLayout>
        <h1 className="font-bold text-4xl text-center">
          {Language.t("instructions.title")}
        </h1>

        <div
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: this.formatHTML(
              Language.t("instructions.introduction.copy"),
            ),
          }}
        />
      </ModalLayout>
    )
  }
}

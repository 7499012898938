import React from "react"
import type { ReactElement, ReactNode } from "react"

import { CloseModalButton } from "../../shared/close_modal_button"
import type { Handler } from "../../lib/handler"

type Props = {
  children: ReactNode
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  dark?: boolean
  footer?: ReactNode
  footerClassName?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  footerRow?: boolean
  hideCloseButton?: boolean
  onDismiss?: Handler
}

type State = {}

export class ModalLayoutComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <div
        className={`min-h-screen flex flex-col items-center select-none ${
          this.props.dark ? "bg-dark" : "bg-bright"
        }`}
      >
        <div
          className={`flex-1 w-full flex flex-col items-center max-w-lg ${
            this.props.className || ""
          }`}
        >
          {this.props.hideCloseButton ? null : (
            <CloseModalButton
              className={this.props.dark ? "text-bright" : "text-dark"}
              onDismiss={this.props.onDismiss}
            />
          )}

          <div
            className={`flex-1 w-full flex flex-col items-center space-y-4 px-4 ${
              this.props.footer ? "pb-4" : "pb-10"
            } ${this.props.className || ""}`}
          >
            {this.props.children}
          </div>

          {this.props.footer ? (
            <div
              className={`w-full flex ${
                this.props.footerRow
                  ? "flex-row space-x-4"
                  : "flex-col space-y-4"
              } px-4 pb-10 ${this.props.footerClassName || ""}`}
            >
              {this.props.footer}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

import { deckIds } from "./ids"
import { Game } from "../game"
import { isEndOfDeck } from "./is_end_of_deck"
import type { Deck } from "."

export const getFirstAvailableDeck = ({
  game,
}: {
  game: Game.Instance
}): Deck.Id | undefined => {
  return deckIds.find((deckId) => {
    return !isEndOfDeck({ deckId, game })
  })
}

import React from "react"
import type { ComponentProps, ReactElement, ReactNode } from "react"

import { NavigationContext } from "./context"
import { navigationModals } from "./modals"
import { navigationScreens } from "./screens"
import type { Game } from "../game"
import type { Navigation } from "."

type Props = {
  gameContext: Game.ContextData
  initialScreenId: Navigation.ScreenId
  initialScreenProps: Navigation.WithoutInjectedProps<
    ComponentProps<Navigation.Screens[Navigation.ScreenId]>
  >
}

type State = {
  modalId?: Navigation.ModalId
  modalProps: Navigation.WithoutInjectedProps<
    ComponentProps<Navigation.Modals[Navigation.ModalId]>
  >
  screenId: Navigation.ScreenId
  screenProps: Navigation.WithoutInjectedProps<
    ComponentProps<Navigation.Screens[Navigation.ScreenId]>
  >
}

export class NavigationContainer extends React.PureComponent<Props, State> {
  public state: State = {
    modalId: undefined,
    modalProps: {},
    screenId: this.props.initialScreenId,
    screenProps: this.props.initialScreenProps,
  }

  private getScreenId: Navigation.Functions.GetScreenId = () => {
    return this.state.screenId
  }

  private hideModal: Navigation.Functions.HideModal = () => {
    this.setState({
      modalId: undefined,
      modalProps: {},
    })
  }

  private navigate: Navigation.Functions.Navigate = ({ props, screenId }) => {
    this.setState({
      screenId: screenId,
      screenProps: props,
    })
  }

  public render = (): ReactElement => {
    const { gameContext } = this.props

    return (
      <NavigationContext.Provider
        value={{
          getScreenId: this.getScreenId,
          hideModal: this.hideModal,
          navigate: this.navigate,
          props: this.state.screenProps,
          screenId: this.state.screenId,
          showModal: this.showModal,
          updateModal: this.updateModal,
        }}
      >
        <div className={`theme-${this.props.gameContext.game.deckId}`}>
          {this.state.modalId ? this.renderModal({ gameContext }) : null}
          <div className={this.state.modalId ? "hidden" : ""}>
            {this.renderScreen({ gameContext })}
          </div>
        </div>
      </NavigationContext.Provider>
    )
  }

  private renderModal = ({
    gameContext,
  }: {
    gameContext: Game.ContextData
  }): ReactNode => {
    if (!this.state.modalId) {
      console.error("renderModal called with an undefined modalId")
      return null
    }

    const ModalComponent: React.ElementType =
      navigationModals[this.state.modalId]

    return (
      <NavigationContext.Consumer>
        {(navigation) => {
          return (
            <ModalComponent
              {...this.state.modalProps}
              {...gameContext}
              navigation={navigation}
            />
          )
        }}
      </NavigationContext.Consumer>
    )
  }

  private renderScreen = ({
    gameContext,
  }: {
    gameContext: Game.ContextData
  }): ReactNode => {
    const ScreenComponent: React.ElementType =
      navigationScreens[this.state.screenId]

    return (
      <NavigationContext.Consumer>
        {(navigation) => {
          return (
            <ScreenComponent
              {...this.state.screenProps}
              {...gameContext}
              navigation={navigation}
            />
          )
        }}
      </NavigationContext.Consumer>
    )
  }

  private showModal: Navigation.Functions.ShowModal = ({ modalId, props }) => {
    this.setState({
      modalId,
      modalProps: props,
    })
  }

  private updateModal: Navigation.Functions.UpdateModal = ({
    modalId,
    propUpdates,
  }) => {
    if (modalId !== this.state.modalId) {
      console.error(
        `updateModal called with modalId ${modalId} but the current modal is ${this.state.modalId}`,
      )
      return
    }

    this.setState({
      modalId,
      modalProps: {
        ...this.state.modalProps,
        ...propUpdates,
      },
    })
  }
}

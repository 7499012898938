import { I18n } from "i18n-js"

import de from "./locale/de.json" assert { type: "json" }
import en from "./locale/en.json" assert { type: "json" }
import es from "./locale/es_419.json" assert { type: "json" }
import fil from "./locale/fil.json" assert { type: "json" }
import fr from "./locale/fr.json" assert { type: "json" }
import id from "./locale/id.json" assert { type: "json" }
import ko from "./locale/ko.json" assert { type: "json" }
import pt from "./locale/pt_BR.json" assert { type: "json" }
import ro from "./locale/ro.json" assert { type: "json" }
import ru from "./locale/ru.json" assert { type: "json" }
import uk from "./locale/uk.json" assert { type: "json" }

export const supportedLocales = [
  "de",
  "en",
  "es",
  "fil",
  "fr",
  "id",
  "ko",
  "pt",
  "ro",
  "ru",
  "uk",
]

const browserLocale =
  document.location.pathname.split("/").filter((p) => p)[0] ||
  document.documentElement.lang

const initialise = (): I18n => {
  const instance = new I18n()

  instance.defaultLocale = "en"
  instance.enableFallback = true

  instance.store({ de })
  instance.store({ en })
  instance.store({ es })
  instance.store({ fil })
  instance.store({ fr })
  instance.store({ id })
  instance.store({ ko })
  instance.store({ pt })
  instance.store({ ro })
  instance.store({ ru })
  instance.store({ uk })

  if (supportedLocales.includes(browserLocale)) {
    instance.locale = browserLocale
  }

  return instance
}

export const i18n = initialise()

import React from "react"
import type { ReactElement } from "react"

import { Game } from "../../lib/game"
import { Statistics } from "../../lib/stats"
import { StatisticSection } from "../statistic_section"
import { Language } from "../../lib/language"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  game: Game.Instance
}

type State = {}

export class StatisticsComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    const { game } = this.props
    const { players } = game
    const plungeCountStatistics = Statistics.getPlungeCounts({ game })
    const mostPlunged = Statistics.getMostPlunged({
      players,
      plungeCountStatistics,
    })
    const biggestPlunger = Statistics.getBiggestPlunger({
      players,
      plungeCountStatistics,
    })
    const questionsAnswered = Statistics.getQuestionsAnswered({
      game,
    })

    return (
      <div className={`flex flex-col space-y-6 ${this.props.className || ""}`}>
        <StatisticSection
          collection={mostPlunged}
          title={Language.t("game_stats.most_plunged.title")}
        />

        <StatisticSection
          collection={biggestPlunger}
          title={Language.t("game_stats.biggest_plunger.title")}
        />

        <StatisticSection
          collection={questionsAnswered}
          title={Language.t("game_stats.questions_answered.title")}
        />
      </div>
    )
  }
}

import { Language } from "../language"
import { Player } from "../player"
import type { Statistics } from "."

export const getBiggestPlungerStatistics = ({
  players,
  plungeCountStatistics,
}: {
  players: Player.Collection
  plungeCountStatistics: Statistics.PlungeCounts[]
}): Statistics.Collection => {
  const sortedByUsedDesc = plungeCountStatistics.sort((a, b) => {
    return b.used - a.used
  })

  const highestOnly = sortedByUsedDesc.filter(({ used }) => {
    return used > 0 && used === sortedByUsedDesc[0].used
  })

  return highestOnly.map(({ used, playerId }) => {
    return {
      label: Player.getName({ playerId, players }),
      result: Language.t("game_stats.biggest_plunger.value", { count: used }),
    }
  })
}

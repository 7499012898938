import { getLanguage } from "./get"
import { getNamedLanguageCollection } from "./get_named_collection"
import { getNamedLanguageCollectionEntries } from "./get_named_collection_entries"
import { i18n } from "../../config/i18n"
import { setLanguage } from "./set"
import { supportedLanguages } from "./supported"
import type { Handler } from "../handler"

export namespace Language {
  export type Locale =
    | "de"
    | "en"
    | "es"
    | "fil"
    | "fr"
    | "id"
    | "ko"
    | "pt"
    | "ro"
    | "ru"
    | "uk"

  export type NamedCollection = Record<Locale, string>

  export type NamedCollectionEntries = [Locale, string][]

  export const get = getLanguage

  export const getNamedCollection = getNamedLanguageCollection

  export const getNamedCollectionEntries = getNamedLanguageCollectionEntries

  export const set = setLanguage

  export const supported: Locale[] = supportedLanguages

  export const t: Functions.Translate = (...args) => i18n.t(...args)

  export namespace Functions {
    export type GetLanguage = () => Locale

    export type SetLanguage = Handler<{ locale: Locale }>

    export type Translate = typeof i18n.t
  }
}

import { allPlayersUsedPlunge } from "./all_players_used"
import { Deck } from "../deck"
import { Progress } from "../progress"
import type { Game } from "../game"

export const nextDeckOfferFromPlunge = ({
  game,
}: {
  game: Game.Instance
}): Deck.Id | undefined => {
  if (game.deckId === "neck_deep") {
    return
  }

  if (game.plungeDeckProgressionRejected[game.deckId]) {
    return
  }

  if (!Progress.isEndOfRound({ game })) {
    return
  }

  if (!allPlayersUsedPlunge({ game: game })) {
    return
  }

  if (game.endOfGameRejected) {
    return
  }

  return Deck.getNext({ game })
}

import type { Deck } from "../deck"
import type { Game } from "."

export const getCurrentGameCardId = ({
  game,
}: {
  game: Game.Instance
}): Deck.CardId => {
  return game.activeCardIds[game.deckId]
}

import type { Deck } from "."

import { blankDeckCollection } from "./blank_collection"
import { deckIds } from "./ids"
import { isValidCard } from "./is_valid_card"
import { Language } from "../language"

export const loadCards = (): Deck.Collection => {
  return deckIds.reduce<Deck.Collection>((decks, deck) => {
    const keys = Object.keys(Language.t(deck))
    keys.forEach((key) => {
      const card = { deck, key }
      if (isValidCard(card)) {
        decks[card.deck].push(card)
      }
    })

    return decks
  }, blankDeckCollection)
}

import { buildRound } from "./build_round"
import type { Game } from "../game"

export const endRound: Game.Functions.BuildGameUpdate = ({
  game,
  pendingUpdates,
}) => {
  const updatedGame = { ...game, ...pendingUpdates }

  return {
    ...pendingUpdates,
    roundId: updatedGame.roundId + 1,
    rounds: [
      ...updatedGame.rounds,
      buildRound({
        id: updatedGame.roundId + 1,
        players: updatedGame.players,
        previousRound: updatedGame.rounds[updatedGame.roundId],
      }),
    ],
    turnId: 0,
  }
}

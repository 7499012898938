import { Language } from "../language"
import { Player } from "../player"
import type { Statistics } from "."

export const getMostPlungedStatistics = ({
  players,
  plungeCountStatistics,
}: {
  players: Player.Collection
  plungeCountStatistics: Statistics.PlungeCounts[]
}): Statistics.Collection => {
  const sortedByAcceptedDesc = plungeCountStatistics.sort((a, b) => {
    return b.accepted - a.accepted
  })

  const highestOnly = sortedByAcceptedDesc.filter(({ accepted }) => {
    return accepted > 0 && accepted === sortedByAcceptedDesc[0].accepted
  })

  return highestOnly.map(({ accepted, playerId }) => {
    return {
      label: Player.getName({ playerId, players }),
      result: Language.t("game_stats.most_plunged.value", { count: accepted }),
    }
  })
}

import { buildPlayersUpdate } from "./build_update"
import { defaultPlayerCount } from "./default_count"
import { getActivePlayers } from "./get_active"
import { getActivePlayersExceptAnswerer } from "./get_active_except_answerer"
import { getDefaultPlayerCollection } from "./get_default_collection"
import { getDefaultPlayerName } from "./get_default_name"
import { getPlayerList } from "./get_list"
import { getPlayerName } from "./get_name"
import { getPlayerNames } from "./get_names"
import { maximumPlayerCount } from "./maximum_count"
import { playerJoinedOrLeft } from "./joined_or_left"
import type { Handler } from "../handler"

export namespace Player {
  export type Collection = Instance[]

  export type Id = number

  export type Instance = {
    active: boolean
    id: Id
    name: Name
  }

  export type Name = string

  export const buildUpdate = buildPlayersUpdate
  export const getActive = getActivePlayers
  export const getActiveExceptAnswerer = getActivePlayersExceptAnswerer
  export const getDefaultCollection = getDefaultPlayerCollection
  export const getDefaultName = getDefaultPlayerName
  export const getList = getPlayerList
  export const getName = getPlayerName
  export const getNames = getPlayerNames
  export const joinedOrLeft = playerJoinedOrLeft

  export const defaultCount = defaultPlayerCount
  export const maximumCount = maximumPlayerCount

  export namespace Functions {
    export type SetPlayers = Handler<{
      players: Player.Collection
    }>
  }
}

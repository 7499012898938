import type { Language } from "."

export const supportedLanguageNames: Record<Language.Locale, string> = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fil: "Filipino",
  fr: "Français",
  id: "Indonesian",
  ko: "한국어",
  pt: "Português",
  ro: "Limba Română",
  ru: "русский",
  uk: "Українська",
}

export const supportedLanguages = Object.keys(
  supportedLanguageNames,
) as Language.Locale[]

export const supportedLanguagesObject = supportedLanguages.reduce<
  Record<Language.Locale, true>
>((result, locale) => {
  result[locale] = true
  return result
}, {} as Record<Language.Locale, true>)

import React from "react"
import type { ReactElement } from "react"

import { Player } from "../../lib/player"
import type { Progress } from "../../lib/progress"
import { Language } from "../../lib/language"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  players: Player.Collection
  turn: Progress.Turn
}

type State = {}

export class PlayerTurnIndicatorComponent extends React.Component<
  Props,
  State
> {
  public render = (): ReactElement => {
    return (
      <h1 className={`text-xl text-center ${this.props.className || ""}`}>
        <span className={`text-dark font-bold`}>
          {Player.getName({
            playerId: this.props.turn.questioner,
            players: this.props.players,
          })}
        </span>
        <span className="text-dark text-opacity-50">
          {" "}
          {Language.t("player_turn_indicator.asks")}{" "}
        </span>
        <span className={`text-dark font-bold`}>
          {Player.getName({
            playerId: this.props.turn.answerer,
            players: this.props.players,
          })}
        </span>
        <span className="text-dark">:</span>
      </h1>
    )
  }
}

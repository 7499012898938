import React from "react"
import type { ReactElement } from "react"

import type { Handler } from "../../lib/handler"

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>["className"]
  disabled?: boolean
  appearance:
    | "fullBright"
    | "fullDark"
    | "hollowBright"
    | "hollowDark"
    | "labelBright"
    | "labelDark"
  omitDefaultPadding?: boolean
  onClick: Handler
  text: string
  textClassName?: React.HTMLAttributes<HTMLParagraphElement>["className"]
}

type State = {}

export class TextButtonComponent extends React.PureComponent<Props, State> {
  private containerClassNames = {
    fullBright: "bg-bright border-2 border-bright",
    fullDark: "bg-dark border-2 border-dark",
    hollowBright: "bg-transparent border-2 border-solid border-bright",
    hollowDark: "bg-transparent border-2 border-solid border-dark",
    labelBright: "bg-transparent border-2 border-transparent",
    labelDark: "bg-transparent border-2 border-transparent",
  }

  private textClassNames = {
    fullBright: "text-dark",
    fullDark: "text-bright",
    hollowBright: "text-bright",
    hollowDark: "text-dark",
    labelBright: "text-bright",
    labelDark: "text-dark",
  }

  public render = (): ReactElement => {
    return (
      <div
        className={`flex justify-center items-center rounded-full ${
          this.props.disabled ? "" : "cursor-pointer"
        } ${this.containerClassNames[this.props.appearance]} ${
          this.props.className || ""
        } ${this.props.omitDefaultPadding || "px-4 py-2"}`}
        onClick={() => {
          if (this.props.disabled) return

          return this.props.onClick()
        }}
      >
        <p
          className={`font-bold text-center select-none ${
            this.textClassNames[this.props.appearance]
          } ${this.props.textClassName || "text-xl"}`}
        >
          {this.props.text}
        </p>
      </div>
    )
  }
}

import { acceptPlunge } from "./accept"
import { allPlayersAcceptedPlunge } from "./all_players_accepted"
import { allPlayersUsedPlunge } from "./all_players_used"
import { getPlungeForCurrentTurn } from "./get_for_current_turn"
import { getPlungeForTurn } from "./get_for_turn"
import { nextDeckOfferFromPlunge } from "./next_deck_offer"
import { otherPlayersUsedPlunge } from "./other_players_used"
import { playerAcceptedPlunge } from "./player_accepted"
import { playerUsedPlunge } from "./player_used"
import type { Deck } from "../deck"
import type { Game } from "../game"
import type { Handler } from "../handler"
import type { Navigation } from "../navigation"
import type { Player } from "../player"
import type { Progress } from "../progress"

export namespace Plunge {
  export type Collection = Instance[]

  export type Instance = {
    acceptedBy: Player.Id
    cardId: Deck.CardId
    deckId: Deck.Id
    roundId: Progress.RoundId
    turnId: Progress.TurnId
    usedBy: Player.Id
  }

  export type Step = 1 | 2 | 3 | 4

  export type StepProps = {
    game: Game.Instance
    navigation: Navigation.ContextData
    nextStep: Handler
    setUsedBy: Handler<{ usedBy: Player.Id }>
    updateGame: Game.Functions.UpdateGame
    usedBy?: Player.Id
  }

  export const accept = acceptPlunge
  export const allPlayersAccepted = allPlayersAcceptedPlunge
  export const allPlayersUsed = allPlayersUsedPlunge
  export const getForCurrentTurn = getPlungeForCurrentTurn
  export const getForTurn = getPlungeForTurn
  export const nextDeckOffer = nextDeckOfferFromPlunge
  export const otherPlayersUsed = otherPlayersUsedPlunge
  export const playerAccepted = playerAcceptedPlunge
  export const playerUsed = playerUsedPlunge
}

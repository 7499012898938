import { i18n } from "../../config/i18n"
import { isSupportedLanguage } from "./is_supported"
import { Language } from "."
import { supportedLanguages } from "./supported"

export const getLanguage: Language.Functions.GetLanguage = () => {
  if (!isSupportedLanguage(i18n.locale))
    throw new Error(
      `i18n.locale is not a supported language. Expected one of ${JSON.stringify(
        supportedLanguages,
      )}, got ${JSON.stringify(i18n.locale)}.`,
    )

  return i18n.locale
}

import React from "react"
import type { ReactElement } from "react"

import { FontAwesomeIcon, faClose } from "../../lib/fontawesome"
import { Navigation } from "../../lib/navigation"
import type { Handler } from "../../lib/handler"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  onDismiss?: Handler
}

type State = {}

export class CloseModalButtonComponent extends React.PureComponent<
  Props,
  State
> {
  public render = (): ReactElement => {
    return (
      <Navigation.Context.Consumer>
        {({ hideModal }) => {
          return (
            <div
              className={`w-full flex flex-row justify-end ${
                this.props.className || ""
              }`}
            >
              <div
                className="cursor-pointer p-4"
                onClick={() => {
                  this.props.onDismiss?.()
                  hideModal()
                }}
              >
                <FontAwesomeIcon className="h-10 w-10" icon={faClose} />
              </div>
            </div>
          )
        }}
      </Navigation.Context.Consumer>
    )
  }
}

import shuffle from "lodash.shuffle"

import type { Deck } from "."

export const shuffleDeckCollection = ({
  decks,
}: {
  decks: Deck.Collection
}): Deck.Collection => {
  return {
    ankle_deep: shuffle(decks["ankle_deep"]),
    waist_deep: shuffle(decks["waist_deep"]),
    neck_deep: shuffle(decks["neck_deep"]),
  }
}

import { Game } from "../game"
import { getActivePlayers } from "./get_active"
import type { Player } from "."

export const getActivePlayersExceptAnswerer = ({
  game,
}: {
  game: Game.Instance
}): Player.Collection => {
  const { answerer } = Game.getCurrentTurn({ game })

  return getActivePlayers(game).filter((player) => player.id !== answerer)
}

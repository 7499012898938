import React from "react"

import type { Game } from "."

export const GameContext = React.createContext<Game.ContextData>({
  game: undefined as unknown as Game.Instance,
  restartGame: () => Promise.resolve(),
  tick: () => Promise.resolve(),
  undo: () => Promise.resolve(),
  updateGame: () => Promise.resolve(),
})

import type { Deck } from "."
import type { Game } from "../game"

export const changeDeck = ({
  deckId,
  game,
}: {
  deckId: Deck.Id
  game: Game.Instance
}): Game.Instance => {
  return {
    ...game,
    deckId,
    turnsSinceDeckChanged: 0,
  }
}

import { Deck } from "../deck"
import { isEndOfRound } from "./is_end_of_round"
import type { Game } from "../game"

export const nextDeckOfferForProgress = ({
  game,
}: {
  game: Game.Instance
}): Deck.Id | undefined => {
  if (game.deckId === "neck_deep") {
    return
  }

  if (game.roundBasedDeckProgressionRejected[game.deckId]) {
    return
  }

  if (game.turnsSinceDeckChanged < 11) {
    return
  }

  if (!isEndOfRound({ game })) {
    return
  }

  if (game.endOfGameRejected) {
    return
  }

  return Deck.getNext({ game })
}

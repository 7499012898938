import React from "react"
import type { ReactElement } from "react"

import { Language } from "../../lib/language"
import { LanguageSetup } from "../../shared/language_setup"
import { ModalLayout } from "../../layouts/modal"
import { Navigation } from "../../lib/navigation"

type Props = {
  navigation: Navigation.ContextData
}

type State = {}

export class LanguageModalComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <ModalLayout>
        <h1 className="text-center text-dark text-3xl font-bold">
          {Language.t("language_modal.title")}
        </h1>

        <LanguageSetup
          className={"flex-1 w-full"}
          onSetLanguage={this.props.navigation.hideModal}
        />
      </ModalLayout>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

import { Language } from "../../lib/language"
import { ModalLayout } from "../../layouts/modal"
import { Navigation } from "../../lib/navigation"
import { TextButton } from "../../shared/text_button"
import type { Game } from "../../lib/game"

type Props = Game.ContextData & {
  navigation: Navigation.ContextData
}

type State = {}

export class SettingsModalComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    const { game, updateGame } = this.props

    return (
      <ModalLayout
        footer={
          game ? (
            <TextButton
              appearance="hollowDark"
              className={`cursor-pointer flex flex-row w-full justify-center items-center px-8 py-4 text-xl`}
              onClick={() => {
                return this.props.navigation.showModal({
                  modalId: "confirmation",
                  props: {
                    text: {
                      cancel: Language.t("end_game_confirmation.cancel"),
                      confirm: Language.t("end_game_confirmation.confirm"),
                      title: Language.t("end_game_confirmation.title"),
                    },
                    onConfirm: () => {
                      this.props.navigation.showModal({
                        modalId: "gameOver",
                        props: {
                          onDismiss: this.props.restartGame,
                        },
                      })
                      this.props.navigation.navigate({
                        props: {},
                        screenId: "introduction",
                      })
                    },
                    onDismiss: () => {
                      this.props.navigation.hideModal()
                    },
                  },
                })
              }}
              text={Language.t("end_game_button.label")}
            />
          ) : null
        }
      >
        <TextButton
          appearance="fullDark"
          className="w-full"
          onClick={() => {
            return this.props.navigation.showModal({
              modalId: "instructions",
              props: {},
            })
          }}
          text={Language.t("settings_modal.instructions")}
          textClassName="text-2xl"
        />
        <TextButton
          appearance="fullDark"
          className="w-full"
          onClick={() => {
            return this.props.navigation.showModal({
              modalId: "language",
              props: {},
            })
          }}
          text={Language.t("settings_modal.language")}
          textClassName="text-2xl"
        />
        <TextButton
          appearance="fullDark"
          className="w-full"
          onClick={() => {
            if (!(game && updateGame)) {
              this.props.navigation.hideModal()
              this.props.navigation.navigate({
                screenId: "setup",
                props: {},
              })
            }

            return this.props.navigation.showModal({
              modalId: "players",
              props: {},
            })
          }}
          text={Language.t("settings_modal.players")}
          textClassName="text-2xl"
        />
      </ModalLayout>
    )
  }
}

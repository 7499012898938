import React from "react"

import type { Navigation } from "."

export const NavigationContext = React.createContext<Navigation.ContextData>({
  getScreenId: () => "introduction",
  hideModal: () => {},
  navigate: () => {},
  props: {},
  screenId: "introduction",
  showModal: () => {},
  updateModal: () => {},
})

import { deckIds } from "./ids"
import { isEndOfDeck } from "./is_end_of_deck"
import type { Deck } from "."
import type { Game } from "../game"

export const getNextDeck = ({
  game,
}: {
  game: Game.Instance
}): Deck.Id | undefined => {
  const currentDeckIndex = deckIds.findIndex((deckId) => {
    return deckId === game.deckId
  })

  const decks = deckIds.slice(currentDeckIndex + 1).filter((deckId) => {
    return !isEndOfDeck({ deckId, game })
  })

  return decks[0]
}

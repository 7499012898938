import { getDeckLevelNumber } from "../deck/get_level_number"
import { Language } from "../language"
import type { Deck } from "../deck"
import type { Game } from "../game"
import type { Statistics } from "."

export const getQuestionsAnsweredStatistics = ({
  game,
}: {
  game: Game.Instance
}): Statistics.Collection => {
  return Object.entries(game.questionsAnswered).map(([deckId, count]) => {
    return {
      label: Language.t(
        `game_stats.questions_answered.level_${getDeckLevelNumber({
          deckId: deckId as Deck.Id,
        })}`,
      ),
      result: `${count}`,
    }
  })
}

import { getBiggestPlungerStatistics } from "./get_biggest_plunger"
import { getMostPlungedStatistics } from "./get_most_plunged"
import { getPlungeCountStatistics } from "./get_plunge_counts"
import { getQuestionsAnsweredStatistics } from "./get_questions_answered"
import type { Deck } from "../deck"
import type { Player } from "../player"

export namespace Statistics {
  export type Collection = Entry[]

  export type Entry = {
    label: string
    result: string
  }

  export type PlungeCounts = {
    accepted: number
    playerId: Player.Id
    used: number
  }

  export type QuestionsAnswered = Record<Deck.Id, number>

  export const getBiggestPlunger = getBiggestPlungerStatistics
  export const getQuestionsAnswered = getQuestionsAnsweredStatistics
  export const getMostPlunged = getMostPlungedStatistics
  export const getPlungeCounts = getPlungeCountStatistics
}

import React from "react"
import type { ReactElement } from "react"

import { Statistics } from "../../lib/stats"
import { StatisticEntry } from "../statistic_entry"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  collection: Statistics.Collection
  title: string
  totalEntry?: Statistics.Entry
}

type State = {}

export class StatisticSectionComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <div
        className={`bg-dark rounded-2xl divide-y-2 divide-solid divide-bright px-4 py-4 ${
          this.props.className || ""
        }`}
      >
        <h2 className="text-bright font-bold text-sm uppercase opacity-80 pb-3">
          {this.props.title}
        </h2>

        <div className="flex flex-col space-y-2 pt-3">
          {this.props.collection.map(({ label, result }, index) => {
            return <StatisticEntry key={index} label={label} result={result} />
          })}
        </div>

        {this.props.totalEntry ? (
          <StatisticEntry
            label={this.props.totalEntry.label}
            result={this.props.totalEntry.result}
          />
        ) : null}
      </div>
    )
  }
}

import React from "react"
import type { ReactNode } from "react"

import { Game } from "../../../lib/game"
import { Language } from "../../../lib/language"
import { Player } from "../../../lib/player"
import { TextButton } from "../../../shared/text_button"
import type { Plunge } from "../../../lib/plunge"
import { ModalLayout } from "../../../layouts/modal"

type State = {}

export class PlungeStepThree extends React.Component<Plunge.StepProps, State> {
  public render = (): ReactNode => {
    if (this.props.usedBy === undefined) {
      console.error(`PlungeStepThree: usedBy should be set by now, but isn't.`)
      return null
    }

    const { answerer } = Game.getCurrentTurn({ game: this.props.game })

    return (
      <ModalLayout
        footer={
          <>
            <TextButton
              appearance="hollowDark"
              className="w-full"
              onClick={() => {
                this.props.navigation.hideModal()
              }}
              text={Language.t("plunge_modal.3.cancel")}
            />
            <TextButton
              appearance="fullDark"
              className="w-full"
              onClick={() => {
                this.props.nextStep()
              }}
              text={Language.t("plunge_modal.3.confirm")}
            />
          </>
        }
        footerRow
      >
        <h1 className="text-dark text-3xl text-center font-bold">
          {Language.t("plunge_modal.3.title", {
            name: Player.getName({
              playerId: answerer,
              players: this.props.game.players,
            }),
          })}
        </h1>
      </ModalLayout>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

import { HeroImage } from "../../../shared/hero_image"
import { Language } from "../../../lib/language"
import { ModalLayout } from "../../../layouts/modal"
import { TextButton } from "../../../shared/text_button"
import type { Plunge } from "../../../lib/plunge"

type State = {}

export class PlungeStepOne extends React.Component<Plunge.StepProps, State> {
  public render = (): ReactElement => {
    return (
      <ModalLayout
        footer={
          <TextButton
            appearance="fullDark"
            className="mx-2"
            onClick={() => this.props.nextStep()}
            text={Language.t("plunge_modal.1.button")}
          />
        }
      >
        <HeroImage className="w-full px-8 max-w-sm" />

        <h1 className="text-dark text-3xl text-center font-bold mt-8">
          {Language.t("plunge_modal.1.title")}
        </h1>
      </ModalLayout>
    )
  }
}

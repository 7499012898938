import { Deck } from "../deck"
import { Player } from "../player"
import { Progress } from "../progress"
import type { Game } from "."

export const startGame = ({
  players,
}: {
  players: Player.Collection
}): Game.Instance => {
  return {
    activeCardIds: {
      ankle_deep: 0,
      waist_deep: 0,
      neck_deep: 0,
    },
    allCardsExpended: false,
    deckId: "ankle_deep",
    decks: Deck.setupCollection(),
    endOfGameRejected: false,
    endOfLastDeck: false,
    players,
    plunges: [],
    plungeDeckProgressionRejected: {
      ankle_deep: false,
      waist_deep: false,
      neck_deep: false,
    },
    questionsAnswered: {
      ankle_deep: 0,
      waist_deep: 0,
      neck_deep: 0,
    },
    roundBasedDeckProgressionRejected: {
      ankle_deep: false,
      waist_deep: false,
      neck_deep: false,
    },
    roundId: 0,
    rounds: [Progress.buildRound({ id: 0, players })],
    turnsSinceDeckChanged: 0,
    turnId: 0,
  }
}

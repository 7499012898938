import React from "react"
import type { ReactElement } from "react"

import { ModalLayout } from "../../layouts/modal"
import { PlayerSetup } from "../../shared/player_setup"

type Props = {}

type State = {}

export class PlayersModalComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <ModalLayout>
        <PlayerSetup />
      </ModalLayout>
    )
  }
}

import { isEndOfRound } from "./is_end_of_round"
import type { Game } from "../game"

export const progressShouldOfferToEndGame = ({
  game,
}: {
  game: Game.Instance
}): boolean => {
  if (game.endOfGameRejected) {
    return false
  }

  if (game.endOfLastDeck) {
    return true
  }

  if (
    game.deckId === "neck_deep" &&
    game.turnsSinceDeckChanged > 11 &&
    isEndOfRound({ game })
  ) {
    return true
  }

  return false
}

import type { Player } from "."

export const playerJoinedOrLeft = ({
  players,
  prevPlayers,
}: {
  players: Player.Collection
  prevPlayers: Player.Collection
}): boolean => {
  return Boolean(
    prevPlayers.find((prevPlayer, index) => {
      return prevPlayer.active !== players[index].active
    }),
  )
}

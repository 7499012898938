import { Progress } from "../progress"
import type { Game } from "../game"
import type { Player } from "."

export const buildPlayersUpdate = ({
  game,
  updates,
}: {
  game: Game.Instance
  updates: Partial<Player.Instance> & { id: Player.Id }
}): Partial<Game.Instance> => {
  const previousVersion = game.players[updates.id]

  const pendingUpdates = {
    players: [
      ...game.players.slice(0, updates.id),
      { ...previousVersion, ...updates },
      ...game.players.slice(updates.id + 1),
    ],
  }

  if (previousVersion.active !== updates.active) {
    return Progress.endRound({ game, pendingUpdates })
  }

  return pendingUpdates
}

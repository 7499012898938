import { GameContainer } from "./container"
import { GameContext } from "./context"
import { getCurrentGameCard } from "./get_current_card"
import { getCurrentGameCardId } from "./get_current_card_id"
import { getCurrentGameTurn } from "./get_current_turn"
import { nextGameTurn } from "./next_turn"
import { skipGameCard } from "./skip_card"
import { startGame } from "./start"
import type { Deck } from "../deck"
import type { Handler } from "../handler"
import type { Player } from "../player"
import type { Plunge } from "../plunge"
import type { Progress } from "../progress"
import type { Statistics } from "../stats"

export namespace Game {
  export type ContextData = {
    game: Instance
    restartGame: Functions.Restart
    tick: Functions.Tick
    undo: Functions.Undo
    updateGame: Functions.UpdateGame
  }

  export type History = Game.Instance[]

  export type Instance = {
    activeCardIds: Deck.ActiveCardIds
    allCardsExpended: boolean
    deckId: Deck.Id
    decks: Deck.Collection
    endOfGameRejected: boolean
    endOfLastDeck: boolean
    players: Player.Collection
    plungeDeckProgressionRejected: Record<Deck.Id, boolean>
    plunges: Plunge.Collection
    questionsAnswered: Statistics.QuestionsAnswered
    roundBasedDeckProgressionRejected: Record<Deck.Id, boolean>
    roundId: Progress.RoundId
    rounds: Progress.Round[]
    turnId: Progress.TurnId
    turnsSinceDeckChanged: number
  }

  export type TurnInfo = {
    activeCardIds: Deck.ActiveCardIds
    deckId: Deck.Id
    roundId: Progress.RoundId
    turnId: Progress.TurnId
  }

  export const Container = GameContainer
  export const Context = GameContext

  export const getCurrentCard = getCurrentGameCard
  export const getCurrentCardId = getCurrentGameCardId
  export const getCurrentTurn = getCurrentGameTurn
  export const nextTurn = nextGameTurn
  export const skipCard = skipGameCard
  export const start = startGame

  export namespace Functions {
    export type BuildGameUpdate = (args: {
      game: Game.Instance
      pendingUpdates?: Partial<Game.Instance>
    }) => Partial<Game.Instance>

    export type OnUpdate = Handler<{ game: Game.Instance }>

    export type Restart = Handler

    export type Tick = Handler<{ game: Game.Instance }>

    export type Undo = Handler

    export type UpdateGame = Handler<Partial<Game.Instance>>
  }
}

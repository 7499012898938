import type { Game } from "../game"
import type { Statistics } from "."

export const getPlungeCountStatistics = ({
  game,
}: {
  game: Game.Instance
}): Statistics.PlungeCounts[] => {
  return game.plunges.reduce<Statistics.PlungeCounts[]>(
    (result, { acceptedBy, usedBy }) => {
      result[acceptedBy]["accepted"]++
      result[usedBy]["used"]++
      return result
    },
    game.players.map(({ id }) => {
      return {
        accepted: 0,
        playerId: id,
        used: 0,
      }
    }),
  )
}

import { changeDeck } from "./change"
import { deckIds } from "./ids"
import { drawNextCard as drawNextCardFn } from "./draw_next_card"
import { getDeckLevelNumber } from "./get_level_number"
import { getFirstAvailableDeck } from "./get_first_available"
import { getNextDeck } from "./get_next"
import { getPreviousDeck } from "./get_previous"
import { isEndOfDeck as isEndOfDeckFn } from "./is_end_of_deck"
import { setupDecks } from "./setup"

export namespace Deck {
  export type ActiveCardIds = Record<Deck.Id, CardId>

  export type Card = {
    deck: Id
    key: string
  }

  export type CardId = number

  export type Collection = Record<Deck.Id, Card[]>

  export type Id = "ankle_deep" | "waist_deep" | "neck_deep"

  export const change = changeDeck
  export const drawNextCard = drawNextCardFn
  export const getFirstAvailable = getFirstAvailableDeck
  export const getLevelNumber = getDeckLevelNumber
  export const getNext = getNextDeck
  export const getPrevious = getPreviousDeck
  export const ids = deckIds
  export const isEndOfDeck = isEndOfDeckFn
  export const setupCollection = setupDecks
}

import React from "react"
import type { ReactElement } from "react"

import { Language } from "../../../lib/language"
import { ModalLayout } from "../../../layouts/modal"
import { Player } from "../../../lib/player"
import { TextButton } from "../../../shared/text_button"
import type { Plunge } from "../../../lib/plunge"

type State = {}

export class PlungeStepTwo extends React.Component<Plunge.StepProps, State> {
  public render = (): ReactElement => {
    return (
      <ModalLayout
        footer={Player.getActiveExceptAnswerer({ game: this.props.game }).map(
          (player) => {
            return (
              <TextButton
                appearance="fullDark"
                key={player.id}
                onClick={() => {
                  this.props.setUsedBy({ usedBy: player.id })
                  this.props.nextStep()
                }}
                text={player.name}
              />
            )
          },
        )}
      >
        <h1 className="text-dark text-3xl text-center font-bold">
          {Language.t("plunge_modal.2.title")}
        </h1>
      </ModalLayout>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

import {
  FontAwesomeIcon,
  faArrowLeft,
  faArrowRight,
  faShuffle,
} from "../../lib/fontawesome"
import { Game } from "../../lib/game"
import type { Handler } from "../../lib/handler"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  gameContext: Game.ContextData
  onNextClick?: Handler
}

type State = {}

export class CardControlsComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <div
        className={`flex flex-row justify-between text-xl ${
          this.props.className || ""
        }`}
      >
        <div
          className="cursor-pointer p-2"
          onClick={() => this.props.gameContext.undo()}
        >
          <FontAwesomeIcon
            className="text-bright opacity-50"
            icon={faArrowLeft}
          />
        </div>

        <div
          className="cursor-pointer p-2"
          onClick={() => {
            const game = Game.skipCard({ game: this.props.gameContext.game })

            return this.props.gameContext.tick({ game })
          }}
        >
          <FontAwesomeIcon
            className="text-bright opacity-50"
            icon={faShuffle}
          />
        </div>

        <div
          className="cursor-pointer p-2"
          onClick={() => this.props.onNextClick?.()}
        >
          <FontAwesomeIcon
            className="text-bright opacity-50"
            icon={faArrowRight}
          />
        </div>
      </div>
    )
  }
}

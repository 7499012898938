import React from "react"
import type { ReactElement } from "react"

import { Language } from "../../lib/language"
import { Navigation } from "../../lib/navigation"
import { TextButton } from "../../shared/text_button"
import type { Handler } from "../../lib/handler"
import { ModalLayout } from "../../layouts/modal"

type Props = {
  onConfirm: Handler
  onDismiss?: Handler
  navigation: Navigation.ContextData
  text: {
    cancel?: string
    confirm?: string
    subtitle?: string
    title: string
  }
}

type State = {}

export class ConfirmationModalComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <ModalLayout className="justify-between items-center" dark>
        <h1 className="text-bright text-4xl text-center font-bold">
          {this.props.text.title}
        </h1>

        {this.props.text.subtitle ? (
          <h2 className="text-bright text-2xl text-center font-semibold">
            {this.props.text.subtitle}
          </h2>
        ) : null}

        <div className="w-full flex max-sm:flex-col sm:flex-row max-sm:justify-center sm:justify-around sm:space-x-4 max-sm:space-y-4">
          <TextButton
            appearance="hollowBright"
            onClick={() => {
              this.props.onDismiss?.()
              this.props.navigation.hideModal()
            }}
            text={this.props.text.cancel || Language.t("cancel")}
          />
          <TextButton
            appearance="fullBright"
            onClick={() => {
              this.props.navigation.hideModal()
              this.props.onConfirm()
            }}
            text={this.props.text.confirm || Language.t("confirm")}
          />
        </div>
      </ModalLayout>
    )
  }
}

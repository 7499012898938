import { Deck } from "../deck"
import type { Game } from "."

export const skipGameCard = ({
  game,
}: {
  game: Game.Instance
}): Game.Instance => {
  return {
    ...game,
    ...Deck.drawNextCard({ game }),
  }
}

import { getPlungeForTurn } from "./get_for_turn"
import type { Game } from "../game"
import type { Plunge } from "."

export const getPlungeForCurrentTurn = ({
  game,
}: {
  game: Game.Instance
}): Plunge.Instance | undefined => {
  return getPlungeForTurn({ game, roundId: game.roundId, turnId: game.turnId })
}

import React, { ReactElement } from "react"

import {
  FontAwesomeIcon,
  faCirclePlus,
  faPen,
  faTrash,
} from "../../lib/fontawesome"
import { Player } from "../../lib/player"
import type { Game } from "../../lib/game"

type Props = {
  gameContext: Game.ContextData
}

type State = {}

export class PlayerSetupComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <div className={`w-full flex flex-col select-all`}>
        {this.props.gameContext.game.players.map((player) => {
          let inputRef: HTMLInputElement

          return (
            <div
              className={`flex flex-row justify-between items-center border-b-2 border-b-dark border-solid ${
                player.active ? "" : "border-opacity-10"
              }`}
              key={player.id}
            >
              <input
                className={`bg-transparent text-dark ${
                  player.active ? "" : "text-opacity-10"
                } text-start text-2xl font-bold w-full my-1 px-2 py-2`}
                disabled={!player.active}
                onSelect={(event) => {
                  if (
                    Player.getDefaultName({ playerId: player.id }) ===
                    event.currentTarget.value
                  ) {
                    event.currentTarget.value = ""
                  }
                }}
                onChange={(event) => {
                  this.updatePlayer({
                    updates: {
                      id: player.id,
                      name: event.target.value.slice(0, 21),
                    },
                  })
                }}
                value={player.name}
                ref={(ref) => {
                  if (ref) {
                    inputRef = ref
                  }
                }}
              />
              <div className="flex flex-row space-x-6 ml-2">
                {player.active ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      inputRef?.select()
                    }}
                  >
                    <FontAwesomeIcon className="h-4 w-4" icon={faPen} />
                  </div>
                ) : null}
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    this.updatePlayer({
                      updates: {
                        active: !player.active,
                        id: player.id,
                      },
                    })
                  }}
                >
                  <FontAwesomeIcon
                    className="h-4 w-4"
                    icon={player.active ? faTrash : faCirclePlus}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  private updatePlayer = ({
    updates,
  }: {
    updates: Partial<Player.Instance> & { id: Player.Id }
  }) => {
    this.props.gameContext.updateGame(
      Player.buildUpdate({
        game: this.props.gameContext.game,
        updates,
      }),
    )
  }
}

import { endRound } from "./end_round"
import { isEndOfRound } from "./is_end_of_round"
import type { Game } from "../game"

export const endTurn: Game.Functions.BuildGameUpdate = ({
  game,
  pendingUpdates = {},
}) => {
  if (isEndOfRound({ game })) {
    return endRound({ game, pendingUpdates })
  }

  return { ...pendingUpdates, turnId: game.turnId + 1 }
}

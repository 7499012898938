import type { Player } from "."

export const getPlayerName = ({
  playerId,
  players,
}: {
  playerId: Player.Id
  players: Player.Collection
}): Player.Name => {
  return players[playerId].name
}

import React from "react"
import type { ReactElement } from "react"

import { HeroImage } from "../../shared/hero_image"
import { Language } from "../../lib/language"
import { ScreenLayout } from "../../layouts/screen"
import { SettingsButton } from "../../shared/settings_button"
import { TextButton } from "../../shared/text_button"
import type { Navigation } from "../../lib/navigation"

type Props = {
  navigation: Navigation.ContextData
}

type State = {}

export class IntroductionScreenComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <ScreenLayout className="select-none">
        <SettingsButton />

        <div className="flex-1 flex flex-col justify-between items-center px-4 pb-10">
          <HeroImage className="landscape:max-w-xs" />

          <p className="text-dark text-center font-bold text-2xl">
            {Language.t("introduction.subtitle")}
          </p>

          <TextButton
            appearance="fullDark"
            className="w-full"
            onClick={() => {
              return this.props.navigation.navigate({
                screenId: "setup",
                props: {},
              })
            }}
            text={Language.t("introduction.play")}
            textClassName="text-2xl"
          />
        </div>
      </ScreenLayout>
    )
  }
}

import type { ComponentProps } from "react"

import { NavigationConsumer } from "./consumer"
import { NavigationContext } from "./context"
import { navigationModals } from "./modals"
import { navigationScreens } from "./screens"
import type { Game } from "../game"

export namespace Navigation {
  export type ContextData = {
    getScreenId: Functions.GetScreenId
    hideModal: Functions.HideModal
    navigate: Functions.Navigate
    props: WithoutInjectedProps<ComponentProps<Screens[keyof Screens]>>
    screenId: keyof Screens
    showModal: Functions.ShowModal
    updateModal: Functions.UpdateModal
  }

  export type InjectedProps = "navigation" | keyof Game.ContextData

  export type ModalId = keyof Modals

  export type Modals = typeof modals

  export type ScreenId = keyof Screens

  export type Screens = typeof screens

  export type WithoutInjectedProps<T> = Omit<T, InjectedProps>

  export const Container = NavigationConsumer
  export const Context = NavigationContext

  export const screens = navigationScreens

  export const modals = navigationModals

  export namespace Functions {
    export type GetScreenId = () => ScreenId

    export type HideModal = () => void

    export type Navigate = <TargetScreenId extends keyof Screens>(args: {
      screenId: TargetScreenId
      props: WithoutInjectedProps<ComponentProps<Screens[TargetScreenId]>>
    }) => void

    export type ShowModal = <ModalId extends keyof Navigation.Modals>(args: {
      modalId: ModalId
      props: WithoutInjectedProps<ComponentProps<Navigation.Modals[ModalId]>>
    }) => void

    export type UpdateModal = <ModalId extends keyof Navigation.Modals>(args: {
      modalId: ModalId
      propUpdates: Partial<
        WithoutInjectedProps<ComponentProps<Navigation.Modals[ModalId]>>
      >
    }) => void
  }
}

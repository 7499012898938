import React from "react"
import type { ReactElement } from "react"

import { Game } from "../../lib/game"
import { GameStatistics } from "../../shared/statistics"
import { Language } from "../../lib/language"
import { ModalLayout } from "../../layouts/modal"
import type { Handler } from "../../lib/handler"

type Props = Game.ContextData & {
  onDismiss: Handler
}

type State = {}

export class GameOverModalComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <ModalLayout onDismiss={this.props.onDismiss}>
        <h1 className="font-bold text-center text-3xl">
          {Language.t("game_over_modal.title")}
        </h1>
        <GameStatistics className="w-full mt-4" game={this.props.game} />
      </ModalLayout>
    )
  }
}

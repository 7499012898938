import React from "react"

type ExternalProps<T, ProvideTo extends string> = Omit<T, ProvideTo>

export const consumeContext = <ContextData, ProvideTo extends string>({
  Context,
  provideTo,
}: {
  Context: React.Context<ContextData>
  provideTo: ProvideTo
}) =>
  function <NestedComponentProps>(
    NestedComponent: React.ComponentType<NestedComponentProps>,
  ) {
    return function (
      externalProps: ExternalProps<NestedComponentProps, ProvideTo>,
    ) {
      return (
        <Context.Consumer>
          {(contextData) => {
            const { [provideTo]: _, ...nestedProps } = externalProps
            return (
              <NestedComponent
                {...(nestedProps as NestedComponentProps)}
                {...{ [provideTo]: contextData }}
              />
            )
          }}
        </Context.Consumer>
      )
    }
  }

import { defaultPlayerCount } from "./default_count"
import { getDefaultPlayerName } from "./get_default_name"
import { getPlayerList } from "./get_list"
import { maximumPlayerCount } from "./maximum_count"
import type { Player } from "."

export const getDefaultPlayerCollection = ({
  activeCount = defaultPlayerCount,
  maximumCount = maximumPlayerCount,
}: {
  activeCount?: number
  maximumCount?: number
} = {}): Player.Collection => {
  return getPlayerList({ playerCount: maximumCount }).map((_, id) => {
    return {
      id,
      name: getDefaultPlayerName({ playerId: id }),
      active: id < activeCount,
    }
  })
}

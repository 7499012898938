import type { Game } from "../game"
import { localStorageKeys } from "./key"

export const saveGameHistory = async ({
  history,
}: {
  history: Game.Instance[]
}): Promise<void> => {
  try {
    return localStorage.setItem(
      localStorageKeys["history"],
      JSON.stringify(history),
    )
  } catch (error) {
    console.error("saveGameHistory failed:", error)
  }
}

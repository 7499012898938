import React from "react"
import type { ReactNode } from "react"

import { Card } from "../../../shared/card"
import { Game } from "../../../lib/game"
import { Language } from "../../../lib/language"
import { ModalLayout } from "../../../layouts/modal"
import { Player } from "../../../lib/player"
import { Plunge } from "../../../lib/plunge"
import { TextButton } from "../../../shared/text_button"

type State = {}

export class PlungeStepFour extends React.Component<Plunge.StepProps, State> {
  public render = (): ReactNode => {
    const { game, usedBy } = this.props
    const { answerer } = Game.getCurrentTurn({ game })

    if (usedBy === undefined) {
      console.error(`PlungeStepFour: usedBy should be set by now, but isn't.`)
      return null
    }

    return (
      <ModalLayout className="justify-between pt-24" hideCloseButton>
        <Card
          className="w-full"
          card={Game.getCurrentCard({ game })}
          hideControls
        />

        <h1 className="text-dark text-xl text-center font-bold">
          {Language.t("plunge_modal.4.title", {
            name: Player.getName({
              playerId: answerer,
              players: game.players,
            }),
          })}
        </h1>

        <TextButton
          appearance="fullDark"
          className="w-full"
          onClick={() => {
            this.props.updateGame(
              Game.nextTurn({ game: Plunge.accept({ game, usedBy }) }),
            )
            return this.props.navigation.hideModal()
          }}
          text={Language.t("plunge_modal.4.button")}
        />
      </ModalLayout>
    )
  }
}

import { localStorageKeys } from "./key"
import type { Game } from "../game"

export const loadGameHistory = async (): Promise<Game.History> => {
  try {
    return JSON.parse(
      localStorage.getItem(localStorageKeys["history"]) || "[]",
    ) as Game.History
  } catch (error) {
    console.error("loadGameHistory failed:", error)
    return []
  }
}

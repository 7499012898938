import React from "react"
import type { ReactElement } from "react"

import { FontAwesomeIcon, faBars } from "../../lib/fontawesome"
import { Navigation } from "../../lib/navigation"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
}

type State = {}

export class SettingsButtonComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <Navigation.Context.Consumer>
        {(navigation) => {
          return (
            <div
              className={`flex flex-row justify-end w-full ${
                this.props.className || ""
              }`}
            >
              <div
                className="cursor-pointer p-4"
                onClick={() =>
                  navigation.showModal({
                    modalId: "settings",
                    props: {},
                  })
                }
              >
                <FontAwesomeIcon className="h-10 w-10" icon={faBars} />
              </div>
            </div>
          )
        }}
      </Navigation.Context.Consumer>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

import { Game } from "../../lib/game"
import { Navigation } from "../../lib/navigation"
import { PlungeStepFour } from "./step/four"
import { PlungeStepOne } from "./step/one"
import { PlungeStepThree } from "./step/three"
import { PlungeStepTwo } from "./step/two"
import { Player } from "../../lib/player"
import type { Plunge } from "../../lib/plunge"

type Props = Game.ContextData & {
  navigation: Navigation.ContextData
}

type State = {
  step: Plunge.Step
  usedBy?: Player.Id
}

export class PlungeModalComponent extends React.PureComponent<Props, State> {
  public state: State = {
    step: 1,
    usedBy: undefined,
  }

  private getStep = () => {
    return {
      1: PlungeStepOne,
      2: PlungeStepTwo,
      3: PlungeStepThree,
      4: PlungeStepFour,
    }[this.state.step]
  }

  private nextStep = () => {
    if (this.state.step > 3) return

    const potentialPlungers = Player.getActiveExceptAnswerer({
      game: this.props.game,
    })

    const step = (this.state.step + 1) as Plunge.Step

    if (step === 2 && potentialPlungers.length === 1) {
      this.setState({
        step: (step + 1) as Plunge.Step,
        usedBy: potentialPlungers[0].id,
      })
      return
    }

    this.setState({ step })
  }

  public render = (): ReactElement => {
    const PlungeStep = this.getStep()

    return (
      <PlungeStep
        game={this.props.game}
        navigation={this.props.navigation}
        nextStep={this.nextStep}
        usedBy={this.state.usedBy}
        setUsedBy={({ usedBy }: { usedBy: Player.Id }) => {
          this.setState({ usedBy })
        }}
        updateGame={this.props.updateGame}
      />
    )
  }
}

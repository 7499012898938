import React from "react"
import type { ReactElement } from "react"

import { CardControls } from "../card_controls"
import { Handler } from "../../lib/handler"
import { Language } from "../../lib/language"
import type { Deck } from "../../lib/deck"

type Props = {
  card: Deck.Card
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  hideControls?: boolean
  onNextClick?: Handler
}

type State = {}

export class CardComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <div
        className={`flex flex-col relative justify-center items-center max-w-xl ${
          this.props.className || ""
        }`}
      >
        <div
          className={`bg-dark opacity-50 absolute -rotate-[-6deg] rounded-3xl w-full h-full z-1`}
        />
        <div
          className={`bg-dark flex flex-col justify-between rounded-3xl w-full h-full z-0 ${
            this.props.className || ""
          }`}
        >
          <div
            className={`flex-1 flex flex-col justify-center px-6 pt-10 ${
              this.props.hideControls ? "pb-10" : "pb-8"
            }`}
          >
            <p
              className={`text-bright align-middle text-center font-bold text-2xl`}
            >
              {Language.t([this.props.card.deck, this.props.card.key])}
            </p>
          </div>

          {this.props.hideControls || (
            <CardControls
              className="px-8 pb-6"
              onNextClick={this.props.onNextClick}
            />
          )}
        </div>
      </div>
    )
  }
}

import type { Game } from "../game"
import type { Player } from "../player"

export const playerUsedPlunge = ({
  game,
  playerId,
}: {
  game: Game.Instance
  playerId: Player.Id
}): boolean => {
  return Boolean(
    game.plunges.find((plunge) => {
      return plunge.deckId === game.deckId && plunge.usedBy === playerId
    }),
  )
}

import { getCurrentGameCardId } from "./get_current_card_id"
import type { Deck } from "../deck"
import type { Game } from "."

export const getCurrentGameCard = ({
  game,
}: {
  game: Game.Instance
}): Deck.Card => {
  return game.decks[game.deckId][getCurrentGameCardId({ game })]
}

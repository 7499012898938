import type { Game } from "../game"
import type { Plunge } from "."
import type { Progress } from "../progress"

export const getPlungeForTurn = ({
  game,
  roundId,
  turnId,
}: {
  game: Game.Instance
  roundId: Progress.RoundId
  turnId: Progress.TurnId
}): Plunge.Instance | undefined => {
  return game.plunges.find((plunge) => {
    return plunge.roundId === roundId && plunge.turnId === turnId
  })
}

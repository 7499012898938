import { Deck } from "../deck"
import { Progress } from "../progress"
import type { Game } from "."

export const nextGameTurn = ({
  game,
}: {
  game: Game.Instance
}): Game.Instance => {
  return {
    ...game,
    ...Deck.drawNextCard({
      game,
      pendingUpdates: Progress.endTurn({ game }),
    }),
    questionsAnswered: {
      ...game.questionsAnswered,
      [game.deckId]: game.questionsAnswered[game.deckId] + 1,
    },
  }
}

import { buildTurnOrder } from "./build_turn_order"
import type { Player } from "../player"
import type { Progress } from "."

export const buildRound = ({
  id,
  players,
  previousRound,
}: {
  id: Progress.RoundId
  players: Player.Collection
  previousRound?: Progress.Round
}): Progress.Round => {
  return {
    id,
    turns: buildTurnOrder({ players, previousRound }),
  }
}

import React from "react"
import type { ReactElement, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  dark?: boolean
  footer?: ReactNode
  footerClassName?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  footerRow?: boolean
}

type State = {}

export class ScreenLayoutComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <div
        className={`min-h-screen flex flex-col items-center select-none ${
          this.props.dark ? "bg-dark" : "bg-bright"
        }`}
      >
        <div
          className={`flex-1 w-full max-w-lg flex flex-col items-center ${
            this.props.className || ""
          }`}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

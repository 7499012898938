import React from "react"
import type { ReactElement } from "react"

import { Deck } from "../../lib/deck"
import { DeckSelectionButton } from "../deck_selection_button"
import { Game } from "../../lib/game"
import { Language } from "../../lib/language"
import type { Handler } from "../../lib/handler"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  game: Game.Instance
  onDeckSelected: Handler<{ game: Game.Instance }>
}

type State = {}

export class DeckSelectionComponent extends React.Component<Props, State> {
  public render = (): ReactElement => {
    return (
      <div className={`flex flex-col space-y-3 ${this.props.className || ""}`}>
        <p className="text-center font-bold">
          {Language.t("deck_selection.label")}
        </p>

        <div className="flex flex-row justify-between">
          {Deck.ids.map((deckId, index) => {
            const { game } = this.props

            return (
              <DeckSelectionButton
                key={deckId}
                lineCount={index + 1}
                onSelected={() => {
                  return this.props.onDeckSelected({
                    game: Deck.change({ deckId, game }),
                  })
                }}
                selected={deckId === game.deckId}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

import { isEndOfDeck } from "./is_end_of_deck"
import type { Game } from "../game"

export const drawNextCard = ({
  game,
  pendingUpdates = {},
}: {
  game: Game.Instance
  pendingUpdates?: Partial<Game.Instance>
}): Partial<Game.Instance> => {
  const updates = { ...pendingUpdates }

  updates["activeCardIds"] = {
    ...game.activeCardIds,
    [game.deckId]: isEndOfDeck({ game })
      ? 0
      : game.activeCardIds[game.deckId] + 1,
  }

  updates["turnsSinceDeckChanged"] = game.turnsSinceDeckChanged + 1

  return updates
}

import { buildRound as buildRoundFn } from "./build_round"
import { endRound as endRoundFn } from "./end_round"
import { endTurn as endTurnFn } from "./end_turn"
import { isEndOfRound as isEndOfRoundFn } from "./is_end_of_round"
import { nextDeckOfferForProgress } from "./next_deck_offer"
import { progressShouldOfferToEndGame } from "./should_offer_to_end_game"
import type { Player } from "../player"

export namespace Progress {
  export type Round = {
    id: RoundId
    turns: Turn[]
  }

  export type RoundId = number

  export type Turn = {
    answerer: Player.Id
    questioner: Player.Id
  }

  export type TurnId = number

  export const buildRound = buildRoundFn
  export const endRound = endRoundFn
  export const endTurn = endTurnFn
  export const isEndOfRound = isEndOfRoundFn
  export const nextDeckOffer = nextDeckOfferForProgress
  export const shouldOfferToEndGame = progressShouldOfferToEndGame
}

import React from "react"
import type { ReactElement } from "react"

import { ReactComponent as LineSVG } from "./line.svg"
import type { Handler } from "../../lib/handler"

type Props = {
  lineCount: number
  onSelected: Handler
  selected: boolean
}

type State = {}

export class DeckSelectionButtonComponent extends React.Component<
  Props,
  State
> {
  public render = (): ReactElement => {
    const textClassName = this.props.selected ? "text-bright" : "text-dark"

    return (
      <div
        className={`flex flex-col items-center justify-center border-2 border-dark h-24 w-24 rounded-full ${
          this.props.selected ? "bg-dark" : "bg-transparent"
        } ${this.props.selected ? "" : "cursor-pointer"}`}
        onClick={() => {
          if (this.props.selected) {
            return
          }

          return this.props.onSelected()
        }}
      >
        {[...Array(this.props.lineCount)].map((_, index) => {
          return <LineSVG className={`${textClassName}`} key={index} />
        })}
      </div>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

import { Game } from "../../lib/game"
import { Navigation } from "../../lib/navigation"
import { Player } from "../../lib/player"
import { PlayerSetup } from "../../shared/player_setup"
import { ScreenLayout } from "../../layouts/screen"
import { TextButton } from "../../shared/text_button"

type Props = Game.ContextData & { navigation: Navigation.ContextData }

type State = {
  players: Player.Collection
}

export class SetupScreenComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <ScreenLayout className="justify-between px-4 py-10">
        <h1 className="font-bold text-center text-3xl">Who's playing?</h1>

        <div className="opacity-50">
          <p>Add the number of people playing.</p>
          <p>You can also add names to help keep track of whose turn it is.</p>
        </div>

        <PlayerSetup />

        <TextButton
          appearance="fullDark"
          className="w-full"
          onClick={() => {
            this.props.navigation.navigate({
              props: {},
              screenId: "play",
            })
          }}
          text="Play"
          textClassName="text-2xl font-bold select-none"
        />
      </ScreenLayout>
    )
  }
}

import React from "react"
import type { ReactElement } from "react"

import { Navigation } from "../../lib/navigation"
import { ReactComponent as BubblesLeftSVG } from "./bubbles_left.svg"
import { ReactComponent as BubblesRightSVG } from "./bubbles_right.svg"
import { ReactComponent as PlungeGraphicSVG } from "./plunge_graphic.svg"
import type { Game } from "../../lib/game"
import type { Progress } from "../../lib/progress"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  game: Game.Instance
  updateGame: Game.Functions.UpdateGame
  turn: Progress.Turn
}

type State = {}

export class PlungeButtonComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    return (
      <Navigation.Context.Consumer>
        {(navigation) => {
          return (
            <div
              className={`cursor-pointer flex flex-row justify-center ${
                this.props.className || ""
              }`}
              onClick={() => {
                navigation.showModal({
                  modalId: "plunge",
                  props: {
                    game: this.props.game,
                    updateGame: this.props.updateGame,
                  },
                })
              }}
            >
              <div className="flex flex-col justify-start">
                <BubblesLeftSVG className="text-dark" />
              </div>

              <div className="bg-dark rounded-full my-8 px-8 py-4 flex justify-center items-center">
                <PlungeGraphicSVG className="text-bright" />
              </div>

              <div className="flex flex-col justify-end">
                <BubblesRightSVG className="text-dark" />
              </div>
            </div>
          )
        }}
      </Navigation.Context.Consumer>
    )
  }
}

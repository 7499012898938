import React from "react"
import type { ReactElement } from "react"

import { Language } from "../../lib/language"
import { TextButton } from "../text_button"
import type { Handler } from "../../lib/handler"

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>["className"]
  onSetLanguage: Handler<{ locale: Language.Locale }>
}

type State = {}

export class LanguageSetupComponent extends React.PureComponent<Props, State> {
  public render = (): ReactElement => {
    const currentLocale = Language.get()

    return (
      <div
        className={`flex flex-col space-y-2 items-center ${
          this.props.className || ""
        }`}
      >
        {Language.getNamedCollectionEntries().map(([locale, languageName]) => {
          return (
            <TextButton
              appearance={locale === currentLocale ? "fullDark" : "hollowDark"}
              className="w-full"
              key={locale}
              onClick={() => {
                Language.set({ locale })
                return this.props.onSetLanguage({ locale })
              }}
              text={languageName}
            />
          )
        })}
      </div>
    )
  }
}

import type { Game } from "."
import type { Progress } from "../progress"

export const getCurrentGameTurn = ({
  game,
}: {
  game: Game.Instance
}): Progress.Turn => {
  return game.rounds[game.roundId].turns[game.turnId]
}

import { getNamedLanguageCollection } from "./get_named_collection"
import type { Language } from "."

export const getNamedLanguageCollectionEntries = (
  ...args: Parameters<typeof getNamedLanguageCollection>
): Language.NamedCollectionEntries => {
  return Object.entries(
    getNamedLanguageCollection(...args),
  ) as Language.NamedCollectionEntries
}

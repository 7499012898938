import type { Deck } from "."
import type { Game } from "../game"

export const isEndOfDeck = ({
  deckId,
  game,
}: {
  deckId?: Deck.Id
  game: Game.Instance
}): boolean => {
  return (
    game.activeCardIds[deckId || game.deckId] ===
    game.decks[deckId || game.deckId].length - 1
  )
}

import { Player } from "../player"
import { playerAcceptedPlunge } from "./player_accepted"
import type { Game } from "../game"

export const allPlayersAcceptedPlunge = ({
  game,
}: {
  game: Game.Instance
}): boolean => {
  return Player.getActive(game).every(({ id: playerId }): boolean => {
    return playerAcceptedPlunge({ game, playerId })
  })
}

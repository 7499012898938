import React from "react"
import type { ComponentProps, ReactElement } from "react"

import { Game } from "./lib/game"
import { Navigation } from "./lib/navigation"
import { Persistence } from "./lib/persistence"

type Props = {}

type State = {
  initialScreenId: Navigation.ScreenId
  initialScreenProps: Navigation.WithoutInjectedProps<
    ComponentProps<Navigation.Screens[Navigation.ScreenId]>
  >
  loading: boolean
  savedHistory: Game.History
}

export class App extends React.PureComponent<Props, State> {
  private abortController?: AbortController

  public state: State = {
    initialScreenId: "introduction",
    initialScreenProps: {},
    loading: true,
    savedHistory: [],
  }

  public componentDidMount = async () => {
    if (!this.abortController) {
      this.setViewHeight()

      this.abortController = new AbortController()

      window.addEventListener("resize", this.setViewHeight, {
        signal: this.abortController.signal,
      })
    }

    const savedHistory = await Persistence.loadHistory()

    if (savedHistory.length > 1) {
      this.setState({
        loading: false,
        initialScreenId: "play",
        initialScreenProps: {},
        savedHistory,
      })
      return
    }

    this.setState({ loading: false })
  }

  public render = (): ReactElement => {
    if (this.state.loading) {
      return <div className="bg-bright min-h-screen"></div>
    }

    return (
      <Game.Container savedHistory={this.state.savedHistory}>
        <Navigation.Container
          initialScreenId={this.state.initialScreenId}
          initialScreenProps={this.state.initialScreenProps}
        />
      </Game.Container>
    )
  }

  private setViewHeight = (): void => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }
}

import { Game } from "../game"
import type { Player } from "../player"

export const acceptPlunge = ({
  game,
  usedBy,
}: {
  game: Game.Instance
  usedBy: Player.Id
}): Game.Instance => {
  const { answerer } = Game.getCurrentTurn({ game })

  return {
    ...game,
    plunges: [
      ...game.plunges,
      {
        acceptedBy: answerer,
        cardId: Game.getCurrentCardId({ game }),
        deckId: game.deckId,
        roundId: game.roundId,
        turnId: game.turnId,
        usedBy,
      },
    ],
  }
}

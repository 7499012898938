import React from "react"
import type { ReactNode } from "react"

import { GameContext } from "./context"
import { Persistence } from "../persistence"
import { Player } from "../player"
import { startGame } from "./start"
import type { Game } from "."

type Props = {
  children: ReactNode
  savedHistory: Game.History
}

type State = {
  history: Game.History
}

export class GameContainer extends React.PureComponent<Props, State> {
  public state = {
    history: this.props.savedHistory,
  }

  public componentDidMount = () => {
    if (!this.getLatestVersion()) {
      this.restart()
    }
  }

  public componentDidUpdate = (): Promise<void> => {
    return Persistence.saveHistory({ history: this.state.history })
  }

  private getLatestVersion = (): Game.Instance | undefined => {
    return this.state.history[this.state.history.length - 1]
  }

  public render = (): ReactNode => {
    const game = this.getLatestVersion()

    if (!game) {
      return null
    }

    return (
      <GameContext.Provider
        value={{
          game,
          restartGame: this.restart,
          tick: this.tick,
          undo: this.undo,
          updateGame: this.update,
        }}
      >
        {this.props.children}
      </GameContext.Provider>
    )
  }

  private restart = (): void => {
    this.setState({
      history: [
        startGame({
          players:
            this.getLatestVersion()?.players || Player.getDefaultCollection(),
        }),
      ],
    })

    Persistence.saveHistory({ history: [] })
  }

  private tick = ({ game }: { game: Game.Instance }): void => {
    this.setState({ history: [...this.state.history, game] })
  }

  private undo = () => {
    if (this.state.history.length < 2) {
      return
    }

    this.setState({ history: this.state.history.slice(0, -1) })
  }

  private update: Game.Functions.UpdateGame = (updates) => {
    const game = this.getLatestVersion()

    if (!game) {
      return
    }

    return this.tick({ game: { ...game, ...updates } })
  }
}

import { Player } from "../player"
import { playerUsedPlunge } from "./player_used"
import type { Game } from "../game"

export const otherPlayersUsedPlunge = ({
  game,
  playerId,
}: {
  game: Game.Instance
  playerId: Player.Id
}): boolean => {
  return Player.getActive(game).every(({ id: otherPlayerId }): boolean => {
    return (
      otherPlayerId === playerId ||
      playerUsedPlunge({ game, playerId: otherPlayerId })
    )
  })
}
